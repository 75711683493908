import React, { useContext, useRef } from "react";
import NumberFormat from 'react-number-format';
import { CInput } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock, updateComportementSortieSimulateurData } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const CustomInput = (props) => {
    const { isPostitShow, className, id, hidePin,  ...inputProps } = props;

    return (
        <div className={className} style={{ padding: 0, position: 'relative', visibility: !props.hidden ? 'visible' : 'hidden' }}>
            {
              props?.addlabelemprunt && <div style={{ position: 'absolute', fontSize: 9, top: -7, left: 7, background: "#fff" }} className="input-text-label-emprunt"><span>{props?.labelemprunt}</span></div>
            }
            <CInput
                id={id}
                {...inputProps}
                style={{
                    width: '100%',
                    paddingRight: 30,
                    textAlign: 'right',
                    paddingLeft: 19,
                    fontSize: 13,
                    ...inputProps.style
                }}
            />
            {
                !inputProps?.withoutUniteEuro && (
                    <span
                        style={{
                            position: "absolute",
                            right: 16,
                            top: 7.5,
                            color: inputProps?.style?.color || "#768192",
                            fontWeight: inputProps?.style?.fontWeight || 'normal',
                            fontSize: 13
                        }}
                    >€</span>
                )
            }
            {
              !hidePin && (
                <PostitPin isPostitShow={isPostitShow} pinId={id} />
              )
            }
        </div>
    )
}

const InputCurrency = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    // props.isPostitShow = simulateurState?.postItData?.displayBlocPostIt;

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
        <NumberFormat
            id={props.id}
            customInput={CustomInput}
            style={{ fontSize: 13, ...props.style }}
            decimalScale={2}
            isAllowed={(values) => {
                const { value } = values;
                return props.min ? value > props.min : value >= 0 || value < 0 || value == '' || value == '.' || value == '-';
            }}
            allowNegative={props.allowNegative ? props.allowNegative : false}
            disabled={props.disabled}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    let index = Array.prototype.indexOf.call(form, event.target);
                    if (form.elements[index + 1])
                        form.elements[index + 1].focus();
                    event.preventDefault();
                }
            }}
            invalid={props.invalid}
            value={props.value == '.' ? '0.' : props.value}
            onChange={/*props.onChange*/handleChange}
            onBlur={props.onBlur}
            className={props.className ? props.className : "col-lg-2"}
            thousandSeparator={' '}
            isPostitShow={simulateurState?.postItData?.displayBlocPostIt}
            hidePin={props.hidePin}
            hidden={props.hidden}
            addlabelemprunt={props.addlabelemprunt}
            labelemprunt={props.labelemprunt}
        />
    )
}

export default InputCurrency;
