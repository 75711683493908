import React from 'react';
import { leftBackground, plusValueBackground, rightBackground, WhiteTableSpace } from '../TableRevenuFoncier/TableRevenuFoncier';
import FlecheBleue from '../../../../../assets/images/svg/PvImmo/FLECHE_BLEUE.png';
import '../TableLocationMeublee/style.scss';

const TableImpotSociete = () => {
    return (
        <table cellPadding={10} cellSpacing={10} style={{ width: '100%', textAlign: 'left' }} >
            <tr>
                <td style={{ fontWeight: 'bold', background: leftBackground, textAlign: 'center' }} >Recettes</td>
                <td></td>
                <td style={{ background: rightBackground }} >Les loyers pris en compte sont les loyers <b>encaissables au titre de l’année d’imposition</b>, même s’ils ont été perçus une autre année.</td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', textAlign: 'center', background: leftBackground }} >
                    <div style={{ marginBottom: 10 }} >-</div>
                    <div>Charges déductibles</div>
                </td>
                <td></td>
                <td style={{ background: rightBackground }} >
                    <div>Les recettes sont diminuées des charges décaissables au titre de l’année d’imposition :</div>
                    <ul>
                        <li><b>Frais d’acquisition</b> (déduits ou amortis) ;</li>
                        <li><b>Tous les travaux</b> (déduits ou amortis) ;</li>
                        <li><b>Amortissement du bien immobilier</b> (sauf terrain) et meubles ;</li>
                        <li>Intérêts et frais d’emprunt ;</li>
                        <li>Charges de copropriété, frais de gestion, primes d’assurance, taxes foncières, rémunération et cotisations sociales le cas échéant.</li>
                    </ul>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr style={{ height: '300px' }} >
                <td style={{ fontWeight: 'bold', textAlign: 'center', background: leftBackground }} >
                    <div style={{ marginBottom: 10 }} >=</div>
                    <div>Net</div>
                </td>
                <td style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: 0 }} >
                    <div style={{ background: 'rgb(237,125,49)', borderRadius: 10, width: '5px', height: '45px', marginBottom: 25 }} ></div>
                    <div style={{ background: 'rgb(101,191,127)', borderRadius: 10, width: '5px', height: '54px' }} ></div>
                </td>
                <td className='cellule' style={{ background: rightBackground, position: 'relative' }} >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                        <div style={{ marginBottom: 20 }} >
                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Déficit</div>
                            <div>Le déficit sera imputé sur les bénéfices des années suivantes.</div>
                        </div>
                        <div>
                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} >Bénéfice</div>
                            <div>Le résultat net est soumis à l’impôt sur les sociétés au taux de 15% jusqu’à 42.500€, puis 25%.</div>
                            <div>Les <b>recettes locatives provenant de biens bâtis depuis au moins 15 ans</b> sont également soumises à la <b>CRL (2,5%)</b>.</div>
                        </div>
                        <div style={{ height: '75px', borderLeft: '2px solid rgb(68,114,196)' }} >
                            <div style={{ marginTop: 10, position: 'absolute', top: 130, left: 10 }} >
                                <img src={FlecheBleue} width={40} />
                            </div>
                            <div style={{ position: 'absolute', top: 135, left: 40, padding: 5, background: 'rgb(132,151,176)', color: '#FFFFFF', fontWeight: 'bold', width: '100px', textAlign: 'center' }} >Réserves</div>
                            <div style={{ position: 'absolute', top: 190, left: 10 }} >
                                <img src={FlecheBleue} width={40} />
                            </div>
                            <div style={{ position: 'absolute', top: 185, left: 40, padding: 5, background: 'rgb(68,114,196)', color: '#FFFFFF', fontWeight: 'bold', width: '100px', textAlign: 'center' }} >Dividendes</div>
                            <div style={{ position: 'absolute', top: 185, left: 150 }} >
                                <div style={{ display: 'flex', marginRight: 8 }} >
                                    <div style={{ background: 'rgb(68,114,196)', width: '5px', borderRadius: 15, marginRight: 5 }} ></div>
                                    <div>
                                        <div style={{ marginBottom: 20 }} >La société peut distribuer des dividendes à ses associés, <b>dans la limite de son résultat et de ses réserves facultatives</b>.</div>
                                        <div>Les dividendes sont soumis par principe au <b>Prélèvement Forfaitaire Unique de 12,8%, et sur option au barème progressif de l’IR</b> (0% à 45% après un abattement de 40%). Les prélèvements sociaux (17,2%) sont également dus.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td style={{ fontWeight: 'bold', textAlign: 'center', background: 'rgb(226,240,217)' }} >Plus-value</td>
                <td></td>
                <td style={{ background: plusValueBackground }} >
                    En cas de vente d’un bien, l’imposition relève du régime des <b>plus-values professionnelles. L’associé appréhende le prix de cession par une distribution de dividendes, imposable</b>.
                </td>
            </tr>
        </table>
    )
}

export default TableImpotSociete;
