import { emptyRowFichePVImmo, greenLabelBackgroundFichePVImmo, labelBackgroundFichePVImmo, rowPropsFichePVImmo, tableMarginFichePVImmo, titleValueStyleFichePVImmo } from "./tableauRevenuFoncier";

// Recettes
const texteValueLigne1 = [
    { text: "Les loyers pris en compte sont les loyers ", options: { breakLine: false } },
    { text: "encaissables au titre de l’année d’imposition", options: { breakLine: false, bold: true } },
    { text: ", même perçus une autre année.", options: {} }
]

// Charges déductibles
const texteLabelLigne2 = [
    { text: "-", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Charges déductibles", options: { breakLine: true } }
]

const texteValueLigne2 = [
    { text: "Régime micro BIC (LMP et LMNP)", options: { bold: true, underline: { color: '000000', style: 'sng' }, breakLine: true } },
    { text: "Régime applicable par principe aux loueurs en entreprises individuelles et EURL si les recettes annuelles du foyer fiscal sont inférieures à 77.700€ ", options: { breakLine: false } },
    { text: "(188.700€ pour les chambres d’hôtes et meublés de tourisme classés).", options: { breakLine: true, italic: true } },
    { text: "", options: { breakLine: true } },
    { text: "Les recettes sont diminuées d’un ", options: { breakLine: false } },
    { text: "abattement de 50% ", options: { bold: true, breakLine: false } },
    { text: "(71% pour les chambres d’hôtes et meublés de tourisme classés).", options: { italic: true, breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Les personnes morales ne peuvent pas bénéficier du micro BIC." },
    { text: "", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Régime réel (LMP et LMNP)", options: { bold: true, underline: { color: '000000', style: 'sng' }, breakLine: true } },
    { text: "Régime applicable sur option si les recettes du foyer fiscal sont inférieures à 77.700€/188.700€, et applicable par principe au-delà.", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Les recettes sont diminuées des charges décaissables au titre de l’année d’imposition :", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Frais d’acquisition ", options: { bullet: { code: '25CF' }, breakLine: false, bold: true } },
    { text: "(déduits ou amortis) ;", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Tous les travaux ", options: { bullet: { code: '25CF' }, breakLine: false, bold: true } },
    { text: "(déduits ou amortis) ;", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Amortissement du bien immobilier ", options: { bullet: { code: '25CF' }, breakLine: false, bold: true } },
    { text: "(sauf terrain) et meubles       ;", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Intérêts et frais d’emprunt ;", options: { breakLine: true, bullet: { code: '25CF' } } },
    { text: "", options: { breakLine: true } },
    { text: "Charges de copropriété, frais de gestion, primes d’assurance, taxes foncières, cotisations sociales le cas échéant.", options: { breakLine: true, bullet: { code: '25CF' } } },
]

export const locationMeubleeData = [
    [
        { text: "Recettes", options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne1, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ],
    [...emptyRowFichePVImmo],
    [
        { text: texteLabelLigne2, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne2, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ]
]

// Tableau 2 de la page suivante
const texteLabelLigne3 = [
    { text: "=", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Net", options: {} }
]

const texteValue1Ligne3 = [
    { text: "Déficit", options: { underline: { color: '000000', style: 'sng' }, bold: true, breakLine: true } },
    { text: "Imputé ", options: { breakLine: false } },
    { text: "sur les BIC de location meublée des 10 années suivantes.", options: { bold: true, breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Bénéfice", options: { underline: { color: '000000', style: 'sng' }, bold: true, breakLine: true } },
    { text: "Soumis au barème progressif de l’IR (0% à 45%).", options: { breakLine: true } },
    { text: "Deux situations sont possibles pour les charges sociales :", options: { breakLine: true } },
    { text: "si les recettes de location saisonnière > 23.000€ : ", options: { breakLine: false, bullet: { code: '25CF' } } },
    { text: "cotisations sociales.", options: { breakLine: true, bold: true } },
    { text: "", options: { breakLine: true } },
    { text: "sinon : prélèvements sociaux (17,2%).", options: { breakLine: false, bullet: { code: '25CF' } } },
]

const texteValue2Ligne3 = [
    { text: "Déficit", options: { underline: { color: '000000', style: 'sng' }, bold: true, breakLine: true } },
    { text: "Imputé ", options: { breakLine: false } },
    { text: "sur le revenu global ", options: { bold: true, breakLine: false } },
    { text: "sans limite", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Bénéfice", options: { underline: { color: '000000', style: 'sng' }, bold: true, breakLine: true } },
    { text: "Le bénéfice est soumis au barème progressif de l’IR (0% à 45%) et aux ", options: { breakLine: false } },
    { text: "cotisations sociales.", options: { bold: true } }
]

export const locationMeubleeData2 = [
    [
        { text: "", options: {} },
        { text: "", options: {} },
        {
            text: [
                { text: "LOUEUR EN MEUBLÉ NON PROFESSIONNEL", options: { breakLine: true } },
                { text: " LMNP" }
            ],
            options: { ...titleValueStyleFichePVImmo, fill: '8497B0', color: 'FFFFFF', bold: true }
        },
        { text: "", options: {} },
        {
            text: [
                { text: "LOUEUR EN MEUBLÉ PROFESSIONNEL", options: { breakLine: true } },
                { text: "LMP" }
            ],
            options: { ...titleValueStyleFichePVImmo, fill: '8497B0', color: 'FFFFFF', bold: true }
        },
    ],
    [
        { text: "", options: { fill: 'FFFFFF', colspan: 5, margin: [ 0, 0, 0, 0 ] } }
    ],
    [
        { text: texteLabelLigne3, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValue1Ligne3, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValue2Ligne3, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo, valign: 'top' } }
    ],
    [
        { text: "", options: { fill: 'FFFFFF', colspan: 5, margin: [ 0, 0, 0, 0 ] } }
    ],
    [
        { text: "Plus-value", options: { ...titleValueStyleFichePVImmo, fill: greenLabelBackgroundFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        {
            text: [
                { text: "L’imposition relève du régime des ", options: { breakLine: false } },
                { text: "plus-values immobilières des particuliers, avec la particularité que la plus-value de cession des LMNP est majorée des amortissement déduits pendant la période de location (réf. loi de finances pour 2025).", options: { bold: true } }
            ],
            options: { ...rowPropsFichePVImmo, fill: 'F8FBF5', margin: tableMarginFichePVImmo }
        },
        { text: "", options: { fill: 'FFFFFF', margin: [ 0, 0, 0, 0 ] } },
        {
            text: [
                { text: "L’imposition relève du régime des ", options: { breakLine: false } },
                { text: "plus-values professionnelles.", options: { bold: true } }
            ],
            options: { ...rowPropsFichePVImmo, fill: 'F8FBF5', margin: tableMarginFichePVImmo }
        },
    ]
]
