import { emptyRowFichePVImmo, greenLabelBackgroundFichePVImmo, rowPropsFichePVImmo, tableMarginFichePVImmo, titleValueStyleFichePVImmo } from "./tableauRevenuFoncier";

// Recettes
const texteValueLigne1 = [
    { text: "Les loyers pris en compte sont les loyers ", options: { breakLine: false } },
    { text: "encaissables au titre de l’année d’imposition,", options: { bold: true, breakLine: true } },
    { text: "même s’ils ont été perçus une autre année." }
]

// Charges déductibles
const texteLabelLigne2 = [
    { text: "-", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Charges déductibles", options: {} }
]

const texteValueLigne2 = [
    { text: "Les recettes sont diminuées des charges décaissables au titre de l’année d’imposition :", options: { breakLine: true } },
    { text: "Frais d’acquisition ", options: { breakLine: false, bold: true, bullet: { code: '25CF' } } },
    { text: "(déduits ou amortis) ;", options: { breakLine: true } },
    // { text: "", options: { breakLine: true } },
    { text: "Tous les travaux ", options: { breakLine: false, bold: true, bullet: { code: '25CF' } } },
    { text: "(déduits ou amortis) ;", options: { breakLine: true } },
    // { text: "", options: { breakLine: true } },
    { text: "Amortissement du bien immobilier ", options: { breakLine: false, bold: true, bullet: { code: '25CF' } } },
    { text: "(sauf terrain) et meubles ;", options: { breakLine: true } },
    // { text: "", options: { breakLine: true } },
    { text: "Intérêts et frais d’emprunt ;", options: { breakLine: true, bullet: { code: '25CF' } } },
    // { text: "", options: { breakLine: true } },
    { text: "Charges de copropriété, frais de gestion, primes d’assurance, taxes foncières, rémunération et cotisations sociales le cas échéant.", options: { breakLine: true, bullet: { code: '25CF' } } }
]

// Net
const texteLabelLigne3 = [
    { text: "=", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Net", options: {} }
]

const texteValueLigne3 = [
    { text: "Déficit", options: { breakLine: true, underline: { color: '000000', style: 'sng' }, bold: true } },
    { text: "Le déficit sera imputé sur les bénéfices des années suivantes.", options: { breakLine: true } },
    { text: "", options: { breakLine: true } },
    { text: "Bénéfice", options: { breakLine: true, underline: { color: '000000', style: 'sng' }, bold: true } },
    { text: "Le résultat net est soumis à l’impôt sur les sociétés au taux de 15% jusqu’à 42.500€, puis 25%.", options: { breakLine: true } },
    { text: "Les ", options: { breakLine: false } },
    { text: "recettes locatives provenant de biens bâtis depuis au moins 15 ans ", options: { breakLine: false, bold: true } },
    { text: "sont également soumises à la ", options: { breakLine: false } },
    { text: "CRL (2,5%).", options: { bold: true } },

]

// Les textes avec flèches
const texteValueFleche = [
    { text: "La société peut distribuer des dividendes à ses associés, ", options: { breakLine: false } },
    { text: "dans la limite de son résultat et de ses réserves facultatives.", options: { breakLine: true, bold: true } },
    { text: "", options: { breakLine: true } },
    { text: "Les dividendes sont soumis par principe au ", options: { breakLine: false } },
    { text: "Prélèvement Forfaitaire Unique de 12,8%, ", options: { breakLine: false, bold: true } },
    { text: "et ", options: { breakLine: false } },
    { text: "sur option au barème progressif de l’IR ", options: { breakLine: false, bold: true } },
    { text: "(0% à 45% après un abattement de 40%). Les prélèvements sociaux (17,2%) sont également dus.", options: {  } }
]

// Plus-value
const texteValueLigne4 = [
    { text: "En cas de vente d’un bien, l’imposition relève du régime des ", options: { breakLine: false } },
    { text: "plus-values professionnelles. L’associé appréhende le prix de cession par une distribution de dividendes, imposable.", options: { bold: true } }
]

export const impotSocieteData = [
    [
        { text: "Recettes", options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne1, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne2, options: { ...titleValueStyleFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne2, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: texteLabelLigne3, options: { ...titleValueStyleFichePVImmo, rowspan: 2 } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne3, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo } }
    ],
    [
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueFleche, options: { ...rowPropsFichePVImmo, margin: tableMarginFichePVImmo, margin: [ 25, 5, 5, 140 ] } }
    ],
    [ ...emptyRowFichePVImmo ],
    [
        { text: 'Plus-value', options: { ...titleValueStyleFichePVImmo, fill: greenLabelBackgroundFichePVImmo } },
        { text: "", options: { fill: 'FFFFFF' } },
        { text: texteValueLigne4, options: { ...rowPropsFichePVImmo, fill: 'F8FBF5', margin: tableMarginFichePVImmo } }
    ],
]
