import React, { createContext, useEffect, useState } from 'react';
import App from './App';
import io from 'socket.io-client';
import { restApiUri } from './config';

import ExitErrorIcon from './assets/icons/exit_error.png'
import ErrorIcon from './assets/icons/error.png'
import { CImg } from '@coreui/react';
import { completeDate } from './utils';
import { now } from './extra/all_ppt_render';

export const usufViagerDefaultData = {
  firstForm: {
    label: "Age de l'usufruitier",
    value: 0
  },
  lastForm: {
    label: "Contre-valeur en pleine-propriété",
    value: 0
  }
};

export const usufTemporaireDefaultData = {
  firstForm: {
    label: "Durée de l'usufruit (nombre d'années)",
    value: 0
  },
  lastForm: {
    label: "Contre-valeur en pleine-propriété",
    value: 0
  }
};

const layoutContextData = {
  menuLevel: {
    cmp: 1,
    pf: 1,
    cs: 1,
  },
  footerButtons: {
    cmp: {},
    pf: {},
    cs: [],
    ficheClient: [],
    usufNPusufViager: [],
    usufNPusufTemporaire: [],
    donsExoneres: [],
    donationsOrdinaires: [],
    donationsEntreprise: [],
    succession: [],
    bilanPatrimonial: [],
    fiscaliteRevenu: [],
    ft: {
      pea: [],
      ct: [],
      av: [],
      capi: [],
      is: [],
      cs: [],
      dmtg: [],
      ifi: [],
      pvImmo: [],
      clauseBeneficiaireAv: [],
      clauseBeneficiaireDemembreeAv: [],
    },
    emprunt: [],
    usufruitEconomique: [],
    ifi: [],
    pvImmo: [],
    accueilPropositionFinanciere: [],
    propositionFinanciere: []
  },
  simulateurState: {
    usufNPusufViager: usufViagerDefaultData,
    usufNPusufTemporaire: usufTemporaireDefaultData
  },
  fiscaliteRevenu: {
    avecImportClient: 0,
    situationFamiliale: 0,
    repartitionBrutNet: 1,
    versionFicheClient: null
  },
  bilanPatrimonial: {
    indexTab: 0,
    idBilan: undefined,
    activeSimu: false,
    affichage: 1,
    situationDu: completeDate(now),
    activeForm: false,
    idSimulation: null,
    avecImport: false,
    infoSimulation: {
      identifiant_simulation: "",
      clientId: null,
      commentaire: ""
    },
    patrimoineGlobal: {
      avecImportClient: 0,
      situationFamiliale: 0,
      repartitionBrutNet: 0,
      date_import: null,
      versionFicheClient: null
    },
    professionnel: {
      actifsProfessionnels: []
    },
    immobilier: {
      residencesPrincipales: [],
      residencePrincipaleCollapse: [],
      residenceSecondaires: [],
      immobilierLocatif: [],
      biensRurauxEtForestiers: [],
      monumentsHistoriques: [],
      scpi: [],
      societesImmobiliers: [],
    },
    financier: {
      comptesEpargnesLiquidites: [],
      comptesTitres: [],
      peaPmes: [],
      perins: [],
      autre_epargne_retraites: [],
      perinAssurances: [],
      nonCotes: [],
      capis: [],
      avs: [],
      assuranceDeces: [],
      autresActifsFinanciers: [],
      passifs: []

    },
    autres: {
      actifs: []
    }
  }
};

export const LayoutContext = createContext({
  layoutContextData,
  setLayoutContextData: (layoutContextData) => { }
});

const socket = io.connect(restApiUri);
const App2 = () => {
  const [layoutState, setLayoutState] = useState(layoutContextData);
  const [toast, setToast] = useState(null);

  // Fonction d'envoi du TOKEN au BACK
  const sendToken = (token, idClient, email) => {
    socket.emit('checkSession',
      {
        'token': token,
        'idClient': idClient,
        'email': email
      }
    )
  }

  const [message, setMessage] = useState("");
  const [dataFromSocket, setDataFromSocket] = useState({
    token: "",
    idClient: null
  })

  const token = localStorage.getItem('token');
  const idClient = JSON.parse(localStorage.getItem('user'))?.id;

  useEffect(() => {
    socket.on('serverCheckSession', (data) => {
      if (data.token && data.idClient) {
        const donnee = {
          token: "",
          idClient: null,
          email: '',
        }

        donnee.token = data.token;
        donnee.email = data.email;
        donnee.idClient = data.idClient;

        setDataFromSocket(donnee);
      }
    })

    return () => { socket.close() }
  }, [])

  // Fonction de déconnexion
  const logout = () => {
    const id = JSON.parse(localStorage.getItem('user'))?.id

    socket.emit('disconnectUser', { userId: id });

    if (id) {
      fetch(`${restApiUri}/api/auth/changerEtat/` + id, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(resp => resp.json())
    }
  }

  const [withRedirection, setWithRedirection] = useState(false);

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem('user'))?.id;
    const email = JSON.parse(localStorage.getItem('user'))?.email;
    if (id) {
      socket.emit('join', { userId: id, email })
    }
  }, [])

  useEffect(() => {
    if (![null, 'null'].includes(token) && ![null, 'null', undefined, 'undefined'].includes(idClient) && dataFromSocket.token !== "" && dataFromSocket.idClient !== null) {
      if (dataFromSocket.idClient === idClient && dataFromSocket.token !== token) {
        setToast({
          id: 1,
          title: 'ERREUR',
          description: "Votre session va être interrompue automatiquement en raison d'une connexion simultanée détectée sur votre compte.",
          autoDelete: true,
          backgroundColor: '#FFDFDF',
          color: '#C58080',
          exitIcon: ExitErrorIcon,
          icon: ErrorIcon
        })

        setTimeout(() => {
          setToast(null);
          setTimeout(() => {
            logout();
            setWithRedirection(true);
            localStorage.clear()
          }, 2000)
        }, 5000)
      }
    }
  }, [JSON.stringify(dataFromSocket)])

  const position = "top-right"

  return (
    <LayoutContext.Provider value={{ layoutState, setLayoutState }} >
      <App socket={socket} sendToken={sendToken} withRedirection={withRedirection} setWithRedirection={setWithRedirection} />
      {
        toast && (
          <div className={`notification-container ${position}`}>
            <div
              className={`notification toast ${position}`}
              style={{ backgroundColor: toast.backgroundColor, width: 500 }}
            >
              <div className="notification-image">
                <img src={toast.icon} alt="" />
              </div>
              <div style={{ flex: 1 }}>
                <p className="notification-title" style={toast.color ? { color: toast.color } : {}}>{toast.title}</p>
                <p className="notification-message" style={toast.color ? { color: toast.color } : {}}>
                  {toast.description}
                </p>
              </div>
              {
                toast.exitIcon &&
                <CImg className='button' src={toast.exitIcon} style={{ width: 20 }} onClick={() => setToast(null)} />
              }
              {
                !toast.exitIcon &&
                <button onClick={() => setToast(null)}>
                  X
                </button>
              }
            </div>
          </div>
        )
      }
    </LayoutContext.Provider>
  );
};

export default App2;
