import React from 'react';
import { WhiteTableSpace } from '../TableRevenuFoncier/TableRevenuFoncier';

const TableSynthese2 = () => {
    const leftBackground = 'rgb(132,151,176)';

    const grayBorder = {
        border: '1px solid rgb(173,185,202)'
    }

    const titleStyle = { background: leftBackground, textAlign: 'center', fontWeight: 'bold', color: '#FFFFFF', ...grayBorder }

    return (
        <table cellPadding={10} cellSpacing={10} style={{ textAlign: 'left' }} >
            <tr>
                <td style={{ width: '1%', textAlign: 'center', border: 'none' }} ></td>
                <td style={{ width: '10%', textAlign: 'center' }} ></td>
                <td style={{ width: '20%', fontWeight: 'bold', background: '#FFFFFF', textAlign: 'center', ...grayBorder }} rowSpan={2} >REVENUS FONCIERS</td>
                <td style={{ textAlign: 'center', background: '#FFFFFF', fontWeight: 'bold', ...grayBorder }} colSpan={2} >LOCATION MEUBLÉE</td>
                <td style={{ width: '20%', textAlign: 'center', background: '#FFFFFF', fontWeight: 'bold', ...grayBorder }} rowSpan={2} >IMPÔT SUR LES SOCIÉTÉS</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td style={{ width: '12%', fontWeight: 'bold', background: 'rgb(255,255,255)', textAlign: 'center', ...grayBorder }} >LMNP</td>
                <td style={{ width: '12%', fontWeight: 'bold', background: 'rgb(255,255,255)', textAlign: 'center', ...grayBorder }} >LMP</td>
            </tr>

            <tr>
                <td></td>
                <td style={{ ...titleStyle }}>Régime de <br />Plus-value</td>
                <td colSpan={2} style={{ ...grayBorder, textAlign: 'center', background: 'rgb(255,242,204)' }}>Plus-value immobilière des particuliers</td>
                <td colSpan={2} style={{ ...grayBorder, textAlign: 'center', background: 'rgb(255,242,204)' }}>Plus-value professionnelle</td>
            </tr>
            <tr>
                <td></td>
                <td style={{ ...titleStyle }}>Calcul de la <br />plus-value</td>
                <td style={{ ...grayBorder }}>
                    <div style={{ marginBottom: 10 }} >Plus-value brute = Prix de cession – Prix d’acquisition (majoré des frais d’acquisition et travaux éligibles)</div>
                    <div>La plus-value brute est diminuée d’abattements pour durée de détention.</div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div style={{ marginBottom: 10 }} >Plus-value brute = Prix de cession – Prix d’acquisition – Amortissements pratiqués*</div>
                    <div style={{ fontWeight: 'bold' }}>* Loi de finances 2025</div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div style={{ marginBottom: 10 }}>
                        <div>Plus-value de court terme </div>
                        <div>= Prix d’acquisition – VNC</div>
                    </div>
                    <div>
                        <div>Plus-value de long terme</div>
                        <div>= Prix de cession – Prix d’acquisition</div>
                    </div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div>Plus-value de court terme </div>
                    <div>= Prix de cession – VNC</div>
                </td>
            </tr>
            <tr>
                <td></td>
                <td style={{ ...titleStyle }}>Imposition de <br />la plus-value</td>
                <td style={{ ...grayBorder }} colSpan={2}>
                    <div style={{ marginBottom: 10 }}>La plus-value nette après abattement au titre de l’impôt sur le revenu est soumise à une imposition de 19%.</div>
                    <div>La plus-value nette après abattement au titre des prélèvements sociaux est soumise aux prélèvements sociaux (17,2%).</div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div style={{ marginBottom: 10 }}>
                        <div>PV de court terme</div>
                        <div>IR (jusqu’à 45%) + CS</div>
                    </div>
                    <div>
                        <div>PV de long terme</div>
                        <div>IR au taux de 12,8% + PS (17,2%)</div>
                    </div>
                </td>
                <td style={{ ...grayBorder }}>
                    <div>Plus-value de court terme</div>
                    <div>IS (15% ou 25%)</div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style={{ background: 'rgb(255,192,0)', borderRadius: 10, width: '5px', height: '100px', marginBottom: 5 }} ></div>
                </td>
                <td style={{ ...titleStyle }}>Possibilités <br /> d’exonération</td>
                <td style={{ ...grayBorder }} colSpan={2}>La plus-value est exonérée d’impôt sur le revenu après un délai de détention de 22 ans, et de prélèvements sociaux après un délai de détention de 30 ans.</td>
                <td style={{ ...grayBorder }}>Possibilité d’exonération d’IR et PS après 5 années si les recettes sont inférieures à 126.000€, les CS restant dues</td>
                <td style={{ ...grayBorder }}>Aucune exonération possible</td>
            </tr>
            <WhiteTableSpace />
            <tr>
                <td></td>
                <td style={{ background: 'rgb(214,220,229)' }} colSpan={5}>IR : impôt sur le revenu  ;  PS : prélèvements sociaux  ;  CS : cotisations sociales  ;   VNC : valeur nette comptable</td>
            </tr>
        </table>
    )
}

export default TableSynthese2;
