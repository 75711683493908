import { CFormGroup, CLabel } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import DataBlock from '../../../../components/DataBlock/DataBlock';
import { InputAge, InputCurrency, InputDate, InputSelect } from '../../../../components/input';
import { DataControlContext } from '../../../../store/context/dataControl';
import Fiscalite from './Fiscalite/Fiscalite';
import Info from './Info/Info';
import Synthese from './Synthese/Synthese';
import { currencyToSend } from '../../../../store/utilsFunc'
import { addToast } from '../../../../store/actions/utils/toast';
import { UtilsContext } from '../../../../store/context/utils';
import { SimulateurContext } from '../../../../store/context/simulateur';
import { resetAction } from '../../../../store/actions/simulateur';
import InfoBulleError from '../../../../components/InfoBulleError/InfoBulleError';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import Aide from './Aide/Aide';
import InfoBulle from '../../../../components/InfoBulle/InfoBulle';
import InputNumber from '../../../../components/input/InputNumber';
import { plusValueImmobiliereDefaultData } from '../../../../store/context/defaultData/plusValueImmobiliere';
import { formatToUniqueId } from '../../../../utils';

const dataBackground = 'rgb(250,250,250)';
const dataPaddingLeft = '16px';
const dataPadding = '20px';

export const comparaisonBetweenDate = (date1, date2) => {
    // La date1 doit être supérieure.
    if (date1 !== "" && date2 !== "") {
        const d1 = date1.split("/");
        const d2 = date2.split("/");

        if (d1.length === 3 && d2.length === 3) {
            if (d1[0] !== "" && d1[1] !== "" && d1[2] !== "" && d2[0] !== "" && d2[1] !== "" && d2[2] !== "") {
                if (d1[2].length === 4 && d2[2].length === 4) {
                    // Pour la première date
                    const jour1 = parseInt(d1[0], 10);
                    const mois1 = parseInt(d1[1], 10);
                    const annee1 = parseInt(d1[2], 10);

                    // Pour la seconde date
                    const jour2 = parseInt(d2[0], 10);
                    const mois2 = parseInt(d2[1], 10);
                    const annee2 = parseInt(d2[2], 10);

                    return new Date(annee2, mois2, jour2) < new Date(annee1, mois1, jour1);
                }
            }
        }
    }

    return null;
}

const style = {
    data: {
        background: dataBackground,
        paddingLeft: dataPaddingLeft,
        paddingTop: dataPadding,
        paddingBottom: dataPadding,
        width: '100%',
        fontSize: 13,
        fontStyle: 'Roboto',
        marginBottom: 5
    }
}

const natureBienCedeData = [
    {
        value: 0,
        label: "Autres biens immobiliers"
    },
    {
        value: 1,
        label: "Terrains à bâtir"
    },
    {
        value: 2,
        label: "Bois et forêts"
    },
    {
        value: 3,
        label: "Construction"
    },
]

const typeSaisieData = [
    {
        value: 0,
        label: "Saisie de la durée"
    },
    {
        value: 1,
        label: "Saisie des dates"
    },
]

const fraisAcquisitionData = [
    {
        value: 0,
        label: "Forfait 7,5%"
    },
    {
        value: 1,
        label: "Frais réels"
    },
]

const abattementExceptionnelData = [
    {
        value: 0,
        label: "Aucun"
    },
    {
        value: 70,
        label: "70%"
    },
    {
        value: 85,
        label: "85%"
    },
]

const situationFamilialeData = [
    {
        value: 1,
        label: "Célibataire"
    },
    {
        value: 0,
        label: "Couple marié ou pacsé"
    },
    {
        level: 2,
        label: 'Pacsé'
    },
    {
        level: 3,
        label: 'Union libre'
    },
    {
        level: 4,
        label: 'Divorcé ou séparé'
    },
    {
        level: 5,
        label: 'Veuf ou veuve'
    },
]

const ouiNonData = [
    {
        value: 0,
        label: 'Non'
    },
    {
        value: 1,
        label: 'Oui'
    }
]

const impactPlafonnementIFIData = [
    {
        value: 0,
        label: 'Calcul désactivé'
    },
    {
        value: 1,
        label: "Calcul activé"
    }
]

const fraisT = [
    {
        value: 0,
        label: "Forfait 15%"
    },
    {
        value: 1,
        label: "Frais réels"
    }
]

const PageHypothesePvImmo = ({
    type,
    hypothese,
    resultat
}) => {
    const labelClass = "col-lg-3 col-md-6 col-sm-7 d-flex justify-content-start";
    const formClass = "col-lg-2 col-md-2 col-sm-2 mr-1";
    const largeFormClass = "col-lg-3 col-md-2 col-sm-2 mr-1";

    const { pvImmobiliereState, setPvImmobiliereState } = useContext(DataControlContext);
    const { utilsState, utilsDispatch } = useContext(UtilsContext);
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const [fraisTravauxData, setFraisTravauxData] = useState([]);
    const [showText, setShowText] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorConstruction, setShowErrorConstruction] = useState(false);

    const handleFormChange = (event, prop) => {
        const pvImmo = JSON.parse(JSON.stringify(pvImmobiliereState));
        const form = JSON.parse(JSON.stringify(hypothese.type_cession === 0 ? pvImmo.form1 : hypothese.type_cession === 1 ? pvImmo.form2 : pvImmo.form3));

        let { value } = event.target;

        // Les valeurs entières
        if ([
            "nature_bien_cede",
            "choix_duree",
            "nb_annee_pleines_detention",
            "frais_acquisition",
            "frais_travaux",
            "abattemennt_exceptionnel",
            "situation_familiale",
            "activer_mecanisme_lissage_cehr",
            "impact_plafonnement_ifi",
            "bien_detenu",
        ].includes(prop)) {
            value = parseInt(value, 10);
        }
        else {
            // Les montants
            if (!["date_cession", "date_acquisition"].includes(prop)) {
                value = currencyToSend(value) || 0;
            }
        }

        /********************** LES MODIFICATIONS SELON LES CONSIGNES***************** */
        // Changement sur la Nature du bien cédé
        if (prop === 'nature_bien_cede') {
            if (value !== 2) {
                form['nb_hectare_vendus'] = 0;
            }

            if (![1, 3].includes(value)) {
                form['abattemennt_exceptionnel'] = 0;
            }

            if (value !== 3 && type !== 2) {
                form.construction = plusValueImmobiliereDefaultData[type === 0 ? 'form1' : 'form2']['construction'];
            }
        }

        // Changement sur le Choix de durée
        if (prop === "choix_duree") {
            if (value === 0) {
                form['date_cession'] = "";
                form['date_acquisition'] = "";
            }
            else {
                form['nb_annee_pleines_detention'] = 0;
            }
        }

        // Changement sur le Frais d'acquisition
        if (prop === 'frais_acquisition') {
            if (value === 0) {
                form['frais_acquisition_part2'] = 0;
            }
        }

        // Changement sur les frais de travaux
        if (prop === 'frais_travaux') {
            if (value === 0) {
                form['frais_travaux_part2'] = 0;
            }
        }

        // Changement sur l'activation de mécanisme de lissage de la CEHR
        if (prop === 'activer_mecanisme_lissage_cehr') {
            if (value === 0) {
                form['rfr_annee_n_1'] = 0;
                form['rfr_annee_n_2'] = 0;
            }
        }

        form[prop] = value;

        pvImmo[hypothese.type_cession === 0 ? 'form1' : hypothese.type_cession === 1 ? 'form2' : 'form3'] = form;

        setPvImmobiliereState(pvImmo);
    }

    // Changement pour CONSTRUCTION
    const handleFormChange2 = (event, prop) => {
        const pvImmo = JSON.parse(JSON.stringify(pvImmobiliereState));
        const form = JSON.parse(JSON.stringify(hypothese.type_cession === 0 ? pvImmo.form1.construction : pvImmo.form2.construction));

        let { value } = event.target;

        // Les valeurs entières
        if ([
            "choix_duree",
            "nb_annee_pleines_detention",
            "frais_acquisition",
            "frais_travaux",
        ].includes(prop)) {
            value = parseInt(value, 10);
        }
        else {
            // Les montants
            if (!["date_cession", "date_acquisition"].includes(prop)) {
                value = currencyToSend(value) || 0;
            }
        }

        /********************** LES MODIFICATIONS SELON LES CONSIGNES***************** */
        // Changement sur le Choix de durée
        if (prop === "choix_duree") {
            if (value === 0) {
                form['date_cession'] = "";
                form['date_acquisition'] = "";
            }
            else {
                form['nb_annee_pleines_detention'] = 0;
            }
        }

        // Changement sur le Frais d'acquisition
        if (prop === 'frais_acquisition') {
            if (value === 0) {
                form['frais_acquisition_part2'] = 0;
            }
        }

        // Changement sur les frais de travaux
        if (prop === 'frais_travaux') {
            if (value === 0) {
                form['frais_travaux_part2'] = 0;
            }
        }

        form[prop] = value;

        pvImmo[hypothese.type_cession === 0 ? 'form1' : 'form2']['construction'] = form;

        setPvImmobiliereState(pvImmo);
    }

    const dataC40 = resultat?.imposition_plus_value_immo?.nb_annee_pleines_detention?.[0]?.annee || 0;

    const prixCessionCorrige = resultat?.prix_cession_corrige || 0;
    const prixCessionCorrige2 = resultat?.prix_cession_corrige_construction || 0;
    const fraisAcquisitionPart1 = resultat?.frais_acquisition_part1 || 0;
    const fraisTravauxPart1 = resultat?.frais_travaux_part1 || 0;
    const prixAcquisitionCorrige = resultat?.prix_acquisition_corrige || 0;
    const prixAcquisitionCorrige2 = resultat?.prix_acquisition_corrige_construction || 0;

    // Affichage d'erreurs sur les dates cas habituels
    useEffect(() => {
        const dateCession = hypothese.date_cession;
        const dateAcquisition = hypothese.date_acquisition;

        if (hypothese.choix_duree === 1 && dateCession !== "" && dateAcquisition !== "") {
            const dCession = dateCession.split("/");
            const dAcquisition = dateAcquisition.split("/");

            if (dCession[2] !== "" && dAcquisition[2] !== "") {
                if (dCession[2] !== "" && dAcquisition[2] !== "") {
                    if (dCession[2].length === 4 && dAcquisition[2].length === 4) {
                        const val1 = parseInt(dCession[2], 10);
                        const val2 = parseInt(dAcquisition[2], 10);
                        if (val1 - 2000 >= 0 && val2 - 2000 >= 0) {
                            const condition = hypothese.choix_duree === 1 && comparaisonBetweenDate(hypothese.date_cession, hypothese.date_acquisition);

                            if (!condition) {
                                setShowError(true);
                            }
                            else {
                                setShowError(false);
                            }
                        }
                        else {
                            setShowError(false);
                        }
                    }
                }
                else {
                    setShowError(false);
                }
            }
            else {
                setShowError(false);
            }
        }
    }, [hypothese.choix_duree, hypothese.date_cession, hypothese.date_acquisition])

    // Cas sur la Construction
    useEffect(() => {
        if (hypothese.construction) {
            const dateCession = hypothese.construction.date_cession;
            const dateAcquisition = hypothese.construction.date_acquisition;

            if (hypothese.construction.choix_duree === 1 && dateCession !== "" && dateAcquisition !== "") {
                const dCession = dateCession.split("/");
                const dAcquisition = dateAcquisition.split("/");

                if (dCession[2] !== "" && dAcquisition[2] !== "") {
                    if (dCession[2] !== "" && dAcquisition[2] !== "") {
                        if (dCession[2].length === 4 && dAcquisition[2].length === 4) {
                            const val1 = parseInt(dCession[2], 10);
                            const val2 = parseInt(dAcquisition[2], 10);
                            if (val1 - 2000 >= 0 && val2 - 2000 >= 0) {
                                const condition = hypothese.construction.choix_duree === 1 && comparaisonBetweenDate(hypothese.construction.date_cession, hypothese.construction.date_acquisition);

                                if (!condition) {
                                    setShowErrorConstruction(true);
                                }
                                else {
                                    setShowErrorConstruction(false);
                                }
                            }
                            else {
                                setShowErrorConstruction(false);
                            }
                        }
                    }
                    else {
                        setShowErrorConstruction(false);
                    }
                }
                else {
                    setShowErrorConstruction(false);
                }
            }
        }
    }, [
        hypothese.construction ? hypothese.construction.choix_duree : 0,
        hypothese.construction ? hypothese.construction.date_cession : 0,
        hypothese.construction ? hypothese.construction.date_acquisition : 0
    ])

    // Affichage d'erreur sur les dates
    useEffect(() => {
        if (showError || showErrorConstruction) {
            utilsDispatch(addToast({ type: 'danger', title: 'ERREUR', description: "Erreur sur les dates : la date de cession doit être postérieure à la date d’acquisition." }));
            simulateurDispatch(resetAction())
        }
    }, [showError, showErrorConstruction])

    // Les valeurs dans le formulaire FRAIS DE TRAVAUX
    useEffect(() => {
        let tab = [];
        const pvImmo = JSON.parse(JSON.stringify(pvImmobiliereState));
        const type = hypothese.type_cession;
        const currentFraisTravaux = pvImmo[type === 0 ? 'form1' : type === 1 ? 'form2' : 'form3']['frais_travaux'];
        const currentFraisTravauxPartie2 = pvImmo[type === 0 ? 'form1' : type === 1 ? 'form2' : 'form3']['frais_travaux_part2'];

        // Terrains à bâtir OU (Autres biens immobiliers et Nb d'années de détention < 5)
        if (hypothese.nature_bien_cede === 1 || ([0].includes(hypothese.nature_bien_cede) && dataC40 < 5)) {
            tab = [
                {
                    value: 1,
                    label: "Frais réels"
                }
            ]

            pvImmo[type === 0 ? 'form1' : type === 1 ? 'form2' : 'form3']['frais_travaux'] = 1;
        }

        // Autres biens immobiliers et Nb d'années de détention >= 5
        if ([0].includes(hypothese.nature_bien_cede) && dataC40 >= 5) {
            tab = [
                {
                    value: 0,
                    label: "Forfait 15%"
                },
                {
                    value: 1,
                    label: "Frais réels"
                }
            ]

            pvImmo[type === 0 ? 'form1' : type === 1 ? 'form2' : 'form3']['frais_travaux'] = currentFraisTravaux;
            pvImmo[type === 0 ? 'form1' : type === 1 ? 'form2' : 'form3']['frais_travaux_part2'] = currentFraisTravaux === 1 ? currentFraisTravauxPartie2 : 0;
        }

        // Bois et forêts
        if (hypothese.nature_bien_cede === 2) {
            pvImmo[type === 0 ? 'form1' : type === 1 ? 'form2' : 'form3']['frais_travaux'] = 1;
            pvImmo[type === 0 ? 'form1' : type === 1 ? 'form2' : 'form3']['frais_travaux_part2'] = 0;
        }

        // Terrain et Construction
        if (hypothese.nature_bien_cede === 3) {
            tab = [
                {
                    value: 0,
                    label: "Forfait 15%"
                },
                {
                    value: 1,
                    label: "Frais réels"
                }
            ];

            pvImmo[type === 0 ? 'form1' : 'form2']['frais_travaux'] = currentFraisTravaux;
        }

        setFraisTravauxData(tab);
        setPvImmobiliereState(pvImmo);
    }, [hypothese.nature_bien_cede, dataC40])

    useEffect(() => {
        if (hypothese.nature_bien_cede === 1 && hypothese.type_cession === 0) {
            const pvImmo = JSON.parse(JSON.stringify(pvImmobiliereState));
            pvImmo.form1.bien_detenu = 0;

            setPvImmobiliereState(pvImmo);
        }
    }, [hypothese.nature_bien_cede])

    const constructionMarginLeft = '4%';
    const conditionConstruction = hypothese.nature_bien_cede === 3;
    const choixDureeConstruction = conditionConstruction && hypothese.construction && hypothese.construction.choix_duree !== undefined ? hypothese.construction.choix_duree : null;
    const conditionAffichageBienDetenu = hypothese.type_cession === 0 ? hypothese.nature_bien_cede !== 1 : hypothese.type_cession === 1 ? false : true;

    return (
        <div style={{ marginBottom: 10 }} >
            <ModalWindow title="AIDE POUR LES SITUATIONS PARTICULIÈRES" show={showModal} onClose={() => setShowModal(!showModal)} >
                <Aide />
            </ModalWindow>
            <DataBlock
                title="Caractéristiques"
                defaultDisplay={true}
                bodyStyle={{
                    background: '#FFFFFF',
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingBottom: 0
                }}
            >
                {
                    ([0, 1].includes(type)) && (
                        <div style={style.data} >
                            {/** Nature du bien cédé */}
                            <CFormGroup className='form-inline col-lg-12' >
                                <CLabel className={labelClass} >Nature du bien cédé</CLabel>
                                <div className={formClass} style={{ position: 'relative', background: 'green' }} >
                                    <InputSelect
                                        id={`${formatToUniqueId("PageHypothesePvImmo nature_bien_cede")}-select`}
                                        custom
                                        style={{ position: 'absolute', right: -4, top: -18, width: 200 }}
                                        className='A'
                                        onChange={(event) => handleFormChange(event, 'nature_bien_cede')}
                                    >
                                        {
                                            natureBienCedeData.map((data, index) => {
                                                return (
                                                    <option key={`NatureBienCede${index + 1}`} value={data.value} selected={hypothese.nature_bien_cede === data.value} >
                                                        {data.label}
                                                    </option>
                                                )
                                            })
                                        }
                                    </InputSelect>
                                </div>
                                {
                                    (hypothese.nature_bien_cede === 0) && (
                                        <InfoBulle
                                            information="Hors résidence principale"
                                        />
                                    )
                                }
                            </CFormGroup>

                            {/** Nb d'hectares vendus */}
                            {
                                (hypothese.type_cession === 0 && hypothese.nature_bien_cede === 2) && (
                                    <CFormGroup className='form-inline' style={{ marginTop: 10 }} >
                                        <CLabel className={labelClass}>Nb d'hectares vendus</CLabel>
                                        <InputNumber
                                            id={`${formatToUniqueId("PageHypothesPvImmo hectares vendus")}-number`}
                                            max={999999}
                                            className={formClass}
                                            value={hypothese.nb_hectare_vendus}
                                            onChange={(event) => handleFormChange(event, 'nb_hectare_vendus')}
                                        />
                                    </CFormGroup>
                                )
                            }
                        </div>
                    )
                }
                {
                    conditionConstruction && (
                        <div style={{ ...style.data, paddingLeft: dataPaddingLeft, paddingTop: '5px', paddingBottom: '5px' }} >
                            <CFormGroup className='form-inline' style={{ marginTop: 5 }} >
                                <CLabel className={labelClass} style={{ fontWeight: 'bold' }} >Ventilation de la plus-value</CLabel>
                                <div className={formClass} style={{ borderRadius: '13px', background: '#F2F2F2', textAlign: 'center', padding: 5, fontWeight: 'bold' }} >Terrain</div>
                                <div className={formClass}></div>
                                <div className={formClass} style={{ borderRadius: '13px', background: '#F2F2F2', textAlign: 'center', padding: 5, fontWeight: 'bold', marginLeft: constructionMarginLeft }} >Construction</div>
                            </CFormGroup>
                        </div>
                    )
                }

                <div style={{ ...style.data, position: 'relative' }} >
                    <div style={{ fontWeight: 'bold', marginBottom: 5 }} >Durée de détention</div>
                    {/*
                        (type !== 2) && (
                            <>
                                <div
                                    className='calculat'
                                    onClick={() => setShowModal(!showModal)}
                                    onMouseEnter={() => setShowText(!showText)}
                                    onMouseLeave={() => setShowText(!showText)}
                                    style={{
                                        color: '#FFFFFF',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        width: '36px',
                                        height: '35px',
                                        fontSize: 20,
                                        background: 'rgb(29,82,160)',
                                        borderRadius: 4,
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        top: 60,
                                        left: !utilsState.sidebarShow ? '41%' : '44%'//'520px'//
                                    }}
                                >?</div>
                                {
                                    showText && (
                                        <p
                                            style={{
                                                backgroundColor: "#6B6B6B",
                                                color: "white",
                                                padding: 10,
                                                width: '240px',
                                                fontSize: 13,
                                                textAlign: "center",
                                                paddingBottom: 5,
                                                paddingTop: 5,
                                                borderRadius: 5,
                                                position: 'absolute',
                                                zIndex: 10,
                                                top: 62.5,
                                                left: '45%'
                                            }}
                                        >Aide pour les situations particulières</p>
                                    )
                                }
                            </>
                        )
                    */}

                    {/** Choisissez votre saisie */}
                    <CFormGroup className='form-inline'>
                        <CLabel className={labelClass} >Choisissez votre saisie</CLabel>
                        <div className={formClass} style={{ display: 'flex', justifyContent: 'flex-end', padding: 0, position: 'relative' }}>
                            <InputSelect
                                id={`${formatToUniqueId("PageHypothesePvImmo choix_duree")}-select`}
                                custom
                                className="col-lg-12"
                                onChange={(event) => handleFormChange(event, 'choix_duree')}
                            >
                                {
                                    typeSaisieData.map((data, index) => {
                                        return (
                                            <option key={`TypeSaisiePvImmo${index}`} value={data.value} selected={hypothese.choix_duree === data.value} >{data.label}</option>
                                        )
                                    })
                                }
                            </InputSelect>
                            {
                                type !== 2 && (
                                    <InfoBulle
                                        help={true}
                                        style={{ position: 'absolute', right: -30, top: 28 }}
                                        information="Appuyez pour consulter l'aide pour les situations particulières"
                                        onRequestClick={() => setShowModal(!showModal)}
                                    />
                                )
                            }
                        </div>
                        <div className={formClass}></div>
                        {
                            (conditionConstruction) && (
                                <InputSelect
                                    id={`${formatToUniqueId("PageHypothesePvImmo choix_duree 2")}-select`}
                                    custom
                                    className={formClass}
                                    onChange={(event) => handleFormChange2(event, 'choix_duree')}
                                    style={{ marginLeft: constructionMarginLeft }}
                                >
                                    {
                                        typeSaisieData.map((data, index) => {
                                            return (
                                                <option key={`TypeSaisiePvImmo${index}`} value={data.value} selected={hypothese.construction.choix_duree === data.value} >{data.label}</option>
                                            )
                                        })
                                    }
                                </InputSelect>
                            )
                        }
                    </CFormGroup>

                    {/** Nb d'années pleines de détention */}
                    {
                        (hypothese.choix_duree === 0 || (choixDureeConstruction === 0)) && (
                            <CFormGroup className='form-inline' >
                                <CLabel className={labelClass} >Nb d'années pleines de détention</CLabel>
                                <div className={formClass} style={{ visibility: hypothese.choix_duree === 0 ? 'visible' : 'hidden', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0 }} >
                                    <InputAge
                                        id={`${formatToUniqueId("PageHypothesePvImmo nb_annee_pleines_detention")}-age`}
                                        style={{ width: '50%' }}
                                        className='A'
                                        value={hypothese.nb_annee_pleines_detention}
                                        onChange={(event) => handleFormChange(event, 'nb_annee_pleines_detention')}
                                    />
                                </div>
                                <div className={formClass}></div>
                                {
                                    (conditionConstruction) && (
                                        <div className={formClass} style={{ visibility: choixDureeConstruction === 0 ? 'visible' : 'hidden', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0, marginLeft: constructionMarginLeft }} >
                                            <InputAge
                                                id={`${formatToUniqueId("PageHypothesePvImmo nb_annee_pleines_detention 2")}-age`}
                                                style={{ width: '50%' }}
                                                className='A'
                                                value={hypothese.construction.nb_annee_pleines_detention}
                                                onChange={(event) => handleFormChange2(event, 'nb_annee_pleines_detention')}
                                            />
                                        </div>
                                    )
                                }
                            </CFormGroup>
                        )
                    }

                    {
                        (hypothese.choix_duree === 1 || choixDureeConstruction === 1) && (
                            <>
                                {/** Date de cession */}
                                <CFormGroup className='form-inline' >
                                    <CLabel className={labelClass} >Date de cession</CLabel>
                                    <InputDate
                                        id={`${formatToUniqueId("PageHypothesePvImmo date_cession")}-date`}
                                        invalid={showError}
                                        className={formClass}
                                        value={hypothese.date_cession}
                                        onChange={(event) => handleFormChange(event, 'date_cession')}
                                        style={{ visibility: hypothese.choix_duree === 1 ? 'visible' : 'hidden' }}
                                    />
                                    <div className={formClass}></div>
                                    {
                                        (conditionConstruction && choixDureeConstruction === 1) && (
                                            <InputDate
                                                id={`${formatToUniqueId("PageHypothesePvImmo date_cession a")}-date`}
                                                invalid={showErrorConstruction}
                                                className={formClass}
                                                value={hypothese.construction.date_cession}
                                                onChange={(event) => handleFormChange2(event, 'date_cession')}
                                                style={{ marginLeft: constructionMarginLeft }}
                                            />
                                        )
                                    }
                                </CFormGroup>

                                {/** Date d'acquisition */}
                                <CFormGroup className='form-inline' >
                                    <CLabel className={labelClass} >Date d'acquisition</CLabel>
                                    <InputDate
                                        id={`${formatToUniqueId("PageHypothesePvImmo date_acquisition")}-date`}
                                        invalid={showError}
                                        className={formClass}
                                        value={hypothese.date_acquisition}
                                        onChange={(event) => handleFormChange(event, 'date_acquisition')}
                                        style={{ visibility: hypothese.choix_duree === 1 ? 'visible' : 'hidden' }}
                                    />
                                    <div className={formClass}></div>
                                    {
                                        (conditionConstruction && choixDureeConstruction === 1) && (
                                            <InputDate
                                                id={`${formatToUniqueId("PageHypothesePvImmo date_acquisition 2")}-date`}
                                                invalid={showErrorConstruction}
                                                className={formClass}
                                                value={hypothese.construction.date_acquisition}
                                                onChange={(event) => handleFormChange2(event, 'date_acquisition')}
                                                style={{ marginLeft: constructionMarginLeft }}
                                            />
                                        )
                                    }
                                </CFormGroup>
                            </>
                        )
                    }
                </div>

                <div style={style.data} >
                    {/** Prix de cession */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} style={{ fontWeight: 'bold' }} >Prix de cession</CLabel>
                        <InputCurrency
                            id={`${formatToUniqueId("PageHypothesePvImmo Prix de cession")}-${type}`}
                            className={formClass}
                            value={hypothese.prix_cession}
                            onChange={(event) => handleFormChange(event, 'prix_cession')}
                        />
                        <div className={formClass}></div>
                        {
                            (conditionConstruction) && (
                                <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo prix_cession")}-${type}`}
                                        className={formClass}
                                        value={hypothese.construction.prix_cession}
                                        onChange={(event) => handleFormChange2(event, 'prix_cession')}
                                    />
                                </div>
                            )
                        }
                    </CFormGroup>

                    {/** Frais de cession */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass}>
                            Frais de cession
                            {
                                (type !== 2) && (
                                    <><br />(mainlevée hypothèque, diagnostics)</>
                                )
                            }
                        </CLabel>
                        <InputCurrency
                            id={`${formatToUniqueId("PageHypothesePvImmo frais_cession")}-${type}`}
                            className={formClass}
                            value={hypothese.frais_cession}
                            onChange={(event) => handleFormChange(event, 'frais_cession')}
                        />
                        <div className={formClass}></div>
                        {
                            (conditionConstruction) && (
                                <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo frais_cession 2")}-${type}`}
                                        className={formClass}
                                        value={hypothese.construction.frais_cession}
                                        onChange={(event) => handleFormChange2(event, 'frais_cession')}
                                    />
                                </div>
                            )
                        }
                    </CFormGroup>

                    {/** Prix de cession corrigé */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} style={{ fontWeight: 'bold' }} >Prix de cession corrigé</CLabel>
                        <InputCurrency
                            id={`${formatToUniqueId("PageHypothesePvImmo Prix de cession corrigé")}-${type}`}
                            style={{ fontWeight: 'bold' }}
                            disabled={true}
                            className={formClass}
                            value={prixCessionCorrige}
                            invalid={prixCessionCorrige < 0}
                        />
                        {
                            (prixCessionCorrige < 0) && (
                                <InfoBulle
                                    type='error'
                                    information="Corriger la saisie."
                                />
                            )
                        }
                        <div className={formClass}></div>
                        {
                            (conditionConstruction) && (
                                <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo Prix de cession corrigé 2")}-${type}`}
                                        style={{ fontWeight: 'bold' }}
                                        disabled={true}
                                        className={formClass}
                                        value={prixCessionCorrige2}
                                        invalid={prixCessionCorrige2 < 0}
                                    />
                                    {
                                        (prixCessionCorrige2 < 0) && (
                                            <InfoBulle
                                                type='error'
                                                information="Corriger la saisie."
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                    </CFormGroup>
                </div>

                <div style={{ ...style.data, marginBottom: 10 }} >
                    {/** Prix d'acquisition */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} style={{ fontWeight: 'bold' }} >Prix d'acquisition</CLabel>
                        <InputCurrency
                            id={`${formatToUniqueId("PageHypothesePvImmo Prix dacquisition")}-${type}`}
                            className={formClass}
                            value={hypothese.prix_acquisition}
                            onChange={(event) => handleFormChange(event, 'prix_acquisition')}
                        />
                        <div className={formClass}></div>
                        {
                            (conditionConstruction) && (
                                <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo Prix dacquisition 2")}-${type}`}
                                        className={formClass}
                                        value={hypothese.construction.prix_acquisition}
                                        onChange={(event) => handleFormChange2(event, 'prix_acquisition')}
                                    />
                                </div>
                            )
                        }
                    </CFormGroup>

                    {/** Charges et indemnités */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass}>Charges et indemnités</CLabel>
                        <InputCurrency
                            id={`${formatToUniqueId("PageHypothesePvImmo charge_indemnite")}-${type}`}
                            className={formClass}
                            value={hypothese.charge_indemnite}
                            onChange={(event) => handleFormChange(event, 'charge_indemnite')}
                        />
                        <div className={formClass}></div>
                        {
                            (conditionConstruction) && (
                                <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo charge_indemnite 2")}-${type}`}
                                        className={formClass}
                                        value={hypothese.construction.charge_indemnite}
                                        onChange={(event) => handleFormChange2(event, 'charge_indemnite')}
                                    />
                                </div>
                            )
                        }
                    </CFormGroup>

                    {/** Frais d'acquisition */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass}>Frais d'acquisition</CLabel>
                        <InputSelect
                            id={`${formatToUniqueId("PageHypothesePvImmo frais_acquisition")}-select`}
                            custom
                            className={formClass}
                            onChange={(event) => handleFormChange(event, 'frais_acquisition')}
                        >
                            {
                                fraisAcquisitionData.map((data, index) => {
                                    return (
                                        <option key={`FraisAcquisition${index}`} value={data.value} selected={hypothese.frais_acquisition === data.value} >{data.label}</option>
                                    )
                                })
                            }
                        </InputSelect>
                        {
                            (hypothese.frais_acquisition === 0) && (
                                <InputCurrency
                                    id={`${formatToUniqueId("PageHypothesePvImmo frais_acquisition")}-${type}`}
                                    disabled={true}
                                    className={formClass}
                                    value={fraisAcquisitionPart1}
                                />
                            )
                        }
                        {
                            (hypothese.frais_acquisition === 1) && (
                                <InputCurrency
                                id={`${formatToUniqueId("PageHypothesePvImmo frais_acquisition 2")}-${type}`}
                                    className={formClass}
                                    value={hypothese.frais_acquisition_part2}
                                    onChange={(event) => handleFormChange(event, 'frais_acquisition_part2')}
                                />
                            )
                        }

                        {
                            (conditionConstruction) && (
                                <>
                                    <InputSelect
                                        id={`${formatToUniqueId("PageHypothesePvImmo frais_acquisition 2")}-select`}
                                        custom
                                        className={formClass}
                                        onChange={(event) => handleFormChange2(event, 'frais_acquisition')}
                                        style={{ marginLeft: constructionMarginLeft }}
                                    >
                                        {
                                            fraisAcquisitionData.filter((element) => element.value !== 0).map((data, index) => {
                                                return (
                                                    <option key={`FraisAcquisition${index}`} value={data.value} selected={hypothese.construction.frais_acquisition === data.value} >{data.label}</option>
                                                )
                                            })
                                        }
                                    </InputSelect>
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo frais_acquisition 3")}-${type}`}
                                        className={formClass}
                                        value={hypothese.construction.frais_acquisition_part2}
                                        onChange={(event) => handleFormChange2(event, 'frais_acquisition_part2')}
                                    />
                                </>
                            )
                        }
                    </CFormGroup>

                    {
                        (type !== 2) && (
                            <>
                                {/** Frais de travaux */}
                                {
                                    (hypothese.nature_bien_cede !== 2) && (
                                        <CFormGroup className='form-inline' >
                                            <CLabel className={labelClass}>
                                              <span>Frais de travaux</span>
                                              <InfoBulle
                                                  information={<>
                                                      Frais de travaux<br /><br />
                                                      Les dépenses de construction, de reconstruction, d’agrandissement, ou d’amélioration supportées par le cédant et réalisées par une entreprise depuis l’achèvement de l’immeuble ou son acquisition si elle est postérieure viennent en majoration du prix d’acquisition. Ces dépenses sont prises en compte : <br /><br />
                                                      - soit, pour leur montant réel, à la condition qu’elles n’aient pas été déjà prises en compte pour la détermination de l’IR et qu’elles ne présentent pas le caractère de dépenses locatives ; Important : en cas de cession d’un bien loué meublé dont le résultat a été imposé dans la catégorie des BIC selon le régime réel, les dépenses de construction, de reconstruction, d’agrandissement, ou d’amélioration doivent être diminuées des amortissements correspondant à ces dépenses admis en déduction en application de l’article 39 C du CGI, à ce titre pris en compte pour la détermination de l'IR.<br /><br />
                                                      - soit, pour un montant forfaitaire de 15 % du prix d’acquisition, à la condition que le contribuable cède l’immeuble plus de cinq ans après son acquisition.

                                                  </>}
                                              />
                                            </CLabel>
                                            <InputSelect
                                                id={`${formatToUniqueId("PageHypothesePvImmo frais_travaux")}-select`}
                                                custom
                                                className={formClass}
                                                onChange={(event) => handleFormChange(event, 'frais_travaux')}
                                            >
                                                {
                                                    fraisTravauxData.map((data, index) => {
                                                        return (
                                                            <option key={`FraisTravaux${index}`} value={data.value} selected={hypothese.frais_travaux === data.value} >{data.label}</option>
                                                        )
                                                    })
                                                }
                                            </InputSelect>
                                            {
                                                (hypothese.frais_travaux === 0) && (
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("PageHypothesePvImmo frais_travaux 8")}-${type}`}
                                                        disabled={true}
                                                        className={formClass}
                                                        value={fraisTravauxPart1}
                                                    />
                                                )
                                            }
                                            {
                                                (hypothese.frais_travaux === 1) && (
                                                    <InputCurrency
                                                        id={`${formatToUniqueId("PageHypothesePvImmo frais_travaux 6")}-${type}`}
                                                        className={formClass}
                                                        value={hypothese.frais_travaux_part2}
                                                        onChange={(event) => handleFormChange(event, 'frais_travaux_part2')}
                                                    />
                                                )
                                            }

                                            {
                                                (conditionConstruction) && (
                                                    <>
                                                        <InputSelect
                                                            id={`${formatToUniqueId("PageHypothesePvImmo frais_travaux 2")}-select`}
                                                            custom
                                                            className={formClass}
                                                            onChange={(event) => handleFormChange2(event, 'frais_travaux')}
                                                            style={{ marginLeft: constructionMarginLeft }}
                                                        >
                                                            {
                                                                fraisT.filter((element) => element.value !== 0).map((data, index) => {
                                                                    return (
                                                                        <option key={`FraisTravaux${index}`} value={data.value} selected={hypothese.construction.frais_travaux === data.value} >{data.label}</option>
                                                                    )
                                                                })
                                                            }
                                                        </InputSelect>
                                                        <InputCurrency
                                                            id={`${formatToUniqueId("PageHypothesePvImmo frais_travaux 2")}-${type}`}
                                                            className={formClass}
                                                            value={hypothese.construction.frais_travaux_part2}
                                                            onChange={(event) => handleFormChange2(event, 'frais_travaux_part2')}
                                                        />
                                                    </>
                                                )
                                            }
                                        </CFormGroup>
                                    )
                                }

                                {/** Frais de voirie, réseaux et distribution */}
                                <CFormGroup className='form-inline' >
                                    <CLabel className={labelClass}>Frais de voirie, réseaux et distribution</CLabel>
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo frais_voirie")}-${type}`}
                                        className={formClass}
                                        value={hypothese.frais_voirie}
                                        onChange={(event) => handleFormChange(event, 'frais_voirie')}
                                    />
                                    <div className={formClass}></div>
                                    {
                                        (conditionConstruction) && (
                                            <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                                <InputCurrency
                                                    id={`${formatToUniqueId("PageHypothesePvImmo frais_voirie 2")}-${type}`}
                                                    className={formClass}
                                                    value={hypothese.construction.frais_voirie}
                                                    onChange={(event) => handleFormChange2(event, 'frais_voirie')}
                                                />
                                            </div>
                                        )
                                    }
                                </CFormGroup>
                            </>
                        )
                    }

                    {/** Amortissements */}
                    {
                      (conditionConstruction || hypothese.nature_bien_cede === 0 || type === 2) && (
                        <CFormGroup className='form-inline' >
                            <CLabel className={labelClass} style={{ flexDirection: 'column', alignItems: 'flex-start' }} >
                                <span style={{ display: 'flex', gap: 4 }}>
                                  <span>Amortissements du prix d'acquisition pour les</span>
                                  <InfoBulle
                                      information={<>
                                          Amortissements minorant le prix d’acquisition. <br /> <br />
                                          En cas de cession d’un local d’habitation ayant fait ou faisant l’objet d’une location meublée relevant des bénéfices industriels et commerciaux (BIC) selon le régime réel, les amortissements du prix d’acquisition admis en déduction en application de l’article 39 C du CGI viennent en minoration de celui-ci. <br /> <br />
                                          En conséquence : est à renseigner le montant des amortissements afférents au bien cédé admis en déduction sur la période de location meublée ; à l'exclusion du montant des amortissements correspondant aux dépenses de construction, reconstruction, agrandissement ou amélioration admis en déduction en application de l’article 39 C du CGI et pris en compte pour la détermination de l’impôt sur le revenu.

                                      </>}
                                  />
                                </span>
                                <span>locaux d'habitation loués meublés (LMNP)</span>
                            </CLabel>
                            <InputCurrency
                                id={`${formatToUniqueId("PageHypothesePvImmo Amortissements")}-${type}`}
                                className={formClass}
                                hidden={conditionConstruction}
                                value={hypothese.amortissement_prix_acquisition_locaux_LMNP}
                                onChange={(event) => handleFormChange(event, 'amortissement_prix_acquisition_locaux_LMNP')}
                            />
                            <div className={formClass}></div>
                            {
                                (conditionConstruction) && (
                                    <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                        <InputCurrency
                                            id={`${formatToUniqueId("PageHypothesePvImmo Amortissements corrigé 2")}-${type}`}
                                            className={formClass}
                                            value={hypothese?.construction?.amortissement_prix_acquisition_locaux_LMNP}
                                            onChange={(event) => handleFormChange2(event, 'amortissement_prix_acquisition_locaux_LMNP')}
                                        />
                                    </div>
                                )
                            }
                        </CFormGroup>
                      )
                      }

                    {/** Prix d'acquisition corrigé */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} style={{ fontWeight: 'bold' }} >Prix d'acquisition corrigé</CLabel>
                        <InputCurrency
                            id={`${formatToUniqueId("PageHypothesePvImmo acquisition corrigé")}-${type}`}
                            disabled={true}
                            className={formClass}
                            value={prixAcquisitionCorrige}
                            style={{ fontWeight: 'bold' }}
                        />
                        <div className={formClass}></div>
                        {
                            (conditionConstruction) && (
                                <div className={formClass} style={{ marginLeft: constructionMarginLeft, padding: 0 }} >
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo acquisition corrigé 2")}-${type}`}
                                        disabled={true}
                                        className={formClass}
                                        value={prixAcquisitionCorrige2}
                                        style={{ fontWeight: 'bold' }}
                                    />
                                </div>
                            )
                        }
                    </CFormGroup>
                </div>
            </DataBlock >

            <DataBlock
                title="Calcul de la plus-value imposable"
                defaultDisplay={true}
                bodyStyle={{
                    background: '#FFFFFF',
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0
                }}
            >
                {
                    ([1, 3].includes(hypothese.nature_bien_cede)) && (
                        <div style={{ ...style.data, position: 'relative', paddingTop: 30, paddingBottom: 30 }} >
                            <div style={{ marginBottom: 5, fontWeight: 'bold' }} >Plus-value imposable à l'IR et aux PS</div>

                            {/** Abattement exceptionnel ? */}
                            <CFormGroup className='form-inline'>
                                <CLabel className={labelClass} >Abattement exceptionnel ?</CLabel>
                                <div className={formClass} style={{ display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0 }} >
                                    <InputSelect
                                        id={`${formatToUniqueId("PageHypothesePvImmo abattemennt_exceptionnel")}-select`}
                                        custom
                                        className='A'
                                        style={{ width: '50%' }}
                                        onChange={(event) => handleFormChange(event, 'abattemennt_exceptionnel')}
                                    >
                                        {
                                            abattementExceptionnelData.map((data, index) => {
                                                return (
                                                    <option key={`AbattementExceptionnel${index}`} value={data.value} selected={hypothese.abattemennt_exceptionnel === data.value} >{data.label}</option>
                                                )
                                            })
                                        }
                                    </InputSelect>
                                </div>
                            </CFormGroup>

                            <Info
                                title="Abattement exceptionnel"
                                content="Certaines plus-values relatives à des opérations de démolition-reconstruction bénéficient d'un abattement exceptionnel pour la détermination de l'assiette imposable à l'IR et aux PS. Le taux de l'abattement exceptionnel est en principe de 70% et peut être majoré à 85%. Il est appliqué après prise en compte, le cas échéant, de l'abattement pour durée de détention."
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '43%',
                                    width: '55%',
                                    height: '120px'
                                }}
                            />
                        </div>
                    )
                }

                {
                    (conditionAffichageBienDetenu) && (
                        <div style={{ ...style.data, position: 'relative', paddingTop: 40, paddingBottom: 40 }} >
                            <div style={{ marginBottom: 5, fontWeight: 'bold' }} >Plus-value imposable à la Surtaxe</div>

                            {/** Bien détenu en indivision ou communauté  ? */}
                            <CFormGroup className='form-inline' >
                                <CLabel className={labelClass} >Bien détenu en indivision ou communauté ? </CLabel>
                                <div className={formClass} style={{ display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0 }} >
                                    <InputSelect id={`${formatToUniqueId("PageHypothesePvImmo bien_detenu")}-select`} custom className='A' style={{ width: '50%' }} onChange={(event) => handleFormChange(event, 'bien_detenu')} >
                                        {
                                            ouiNonData.map((data, index) => {
                                                return (
                                                    <option key={`OuiNon${index + 1}`} value={data.value} selected={hypothese.bien_detenu === data.value} >{data.label}</option>
                                                )
                                            })
                                        }
                                    </InputSelect>
                                </div>
                            </CFormGroup>

                            <Info
                                title="Surtaxe"
                                content="Une taxe s'applique sur certaines plus-values immobilières, autres que les cessions de terrains à bâtir. Cette taxe est due lorsque le montant de la plus-value nette imposable à l'IR est supérieure à 50 000 € par cédant. Elle est calculée selon un barème dont les taux varient de 2% à 6% en fonction du montant de la plus-value imposable. A titre d'illustration, si la plus-value imposable est supérieure à 260 000 €, la taxe est due au taux unique de 6% sur la totalité de la plus-value nette (retenue dès le 1er euro)."
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '43%',
                                    width: '55%',
                                    height: '140px'
                                }}
                            />
                        </div>
                    )
                }

                <div style={{ ...style.data, position: 'relative' }} >
                    <div style={{ marginBottom: 5, fontWeight: 'bold' }} >Plus-value imposable à la CEHR</div>

                    {/** Situation familiale */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} >Situation familiale</CLabel>
                        <InputSelect
                            id={`${formatToUniqueId("PageHypothesePvImmo situation_familiale")}-select`}
                            custom
                            style={{ paddingLeft: 4, paddingRight: 0 }}
                            className={formClass}
                            onChange={(event) => handleFormChange(event, 'situation_familiale')}
                        >
                            {
                                situationFamilialeData.map((data, index) => {
                                    return (
                                        <option key={`SituationFamilialePvImmo${index + 1}`} value={data.value} selected={hypothese.situation_familiale === data.value} >{data.label}</option>
                                    )
                                })
                            }
                        </InputSelect>
                    </CFormGroup>

                    {/** RFR année (N) hors PV IMMO */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} >RFR année (N) hors PV IMMO</CLabel>
                        <InputCurrency
                            id={`${formatToUniqueId("PageHypothesePvImmo rfr_annee_hors_pv")}-${type}`}
                            className={formClass}
                            value={hypothese.rfr_annee_hors_pv}
                            onChange={(event) => handleFormChange(event, 'rfr_annee_hors_pv')}
                        />
                    </CFormGroup>

                    {/** Activer le mécanisme de lissage de la CEHR */}
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} >Activer le mécanisme de lissage de la CEHR</CLabel>
                        <div className={formClass} style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, padding: 0 }} >
                            <InputSelect
                                id={`${formatToUniqueId("PageHypothesePvImmo activer_mecanisme_lissage_cehr")}-select`}
                                custom
                                style={{ width: '50%' }}
                                className='A'
                                onChange={(event) => handleFormChange(event, 'activer_mecanisme_lissage_cehr')}
                            >
                                {
                                    ouiNonData.map((data, index) => {
                                        return (
                                            <option key={`OuiNonPvImmo${index + 1}`} value={data.value} selected={hypothese.activer_mecanisme_lissage_cehr === data.value} >{data.label}</option>
                                        )
                                    })
                                }
                            </InputSelect>
                        </div>
                    </CFormGroup>

                    {
                        (hypothese.activer_mecanisme_lissage_cehr === 1) && (
                            <>
                                {/** RFR année (N-1) */}
                                <CFormGroup className='form-inline' >
                                    <CLabel className={labelClass} >RFR année (N-1)</CLabel>
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo rfr_annee_n_1")}-${type}`}
                                        className={formClass}
                                        value={hypothese.rfr_annee_n_1}
                                        onChange={(event) => handleFormChange(event, 'rfr_annee_n_1')}
                                    />
                                </CFormGroup>

                                {/** RFR année (N-2) */}
                                <CFormGroup className='form-inline' >
                                    <CLabel className={labelClass} >RFR année (N-2)</CLabel>
                                    <InputCurrency
                                        id={`${formatToUniqueId("PageHypothesePvImmo rfr_annee_n_2")}-${type}`}
                                        className={formClass}
                                        value={hypothese.rfr_annee_n_2}
                                        onChange={(event) => handleFormChange(event, 'rfr_annee_n_2')}
                                    />
                                </CFormGroup>
                            </>
                        )
                    }

                    <Info
                        title="CEHR"
                        content="La CEHR est calculée sur le revenu fiscal de référence, après réintégration de la plus-value immobilière pour son montant net imposable à l'IR."
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: '43%',
                            width: '55%'
                        }}
                    />
                </div>

                <div style={{ ...style.data, position: 'relative', paddingTop: 36, paddingBottom: 36, marginBottom: 10 }} >
                    <CFormGroup className='form-inline' >
                        <CLabel className={labelClass} style={{ fontWeight: 'bold' }} >Impact sur le plafonnement de l'IFI</CLabel>
                        <InputSelect
                            id={`${formatToUniqueId("PageHypothesePvImmo impact_plafonnement_ifi")}-select`}
                            custom
                            className={formClass}
                            onChange={(event) => handleFormChange(event, 'impact_plafonnement_ifi')}
                        >
                            {
                                impactPlafonnementIFIData.map((data, index) => {
                                    return (
                                        <option key={`impactPlafonnementIFIPvImmo${index + 1}`} value={data.value} selected={hypothese.impact_plafonnement_ifi === data.value} >{data.label}</option>
                                    )
                                })
                            }
                        </InputSelect>
                    </CFormGroup>

                    <Info
                        title="Plafonnement IFI"
                        content="Le mécanisme du plafonnement de l'IFI est calculé en retenant la plus-value immobilière pour son montant brut, avant application éventuelle des abattements pour durée de détention (et abattement exceptionnel) ou de l'exonération s'agissant de la cession de la résidence principale."
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: '43%',
                            width: '55%',
                            height: '110px'
                        }}
                    />
                </div>
            </DataBlock>

            {/************************RESULTATS******************************** */}
            < DataBlock
                title="Imposition de la plus-value immobilière : SYNTHÈSE"
                defaultDisplay={true}
                titleHeight='57px'
            >
                <div>
                    {/** Synthèse */}
                    <div style={{ width: '100%', marginBottom: 30 }} >
                        <Synthese
                            hypothese={hypothese}
                            type={type}
                            data={resultat.imposition_plus_value_immo ? resultat.imposition_plus_value_immo : {}}
                        />
                    </div>
                </div>
            </DataBlock >

            < DataBlock
                title="Imposition de la plus-value immobilière : DÉTAIL FISCALITÉ"
                defaultDisplay={true}
                titleHeight='57px'
            >
                <div style={{ width: '100%' }} >
                    <Fiscalite
                        hypothese={hypothese}
                        ir={resultat.impot_revenu ? resultat.impot_revenu : {}}
                        ps={resultat.prelevement_sociaux ? resultat.prelevement_sociaux : {}}
                        surtaxe={resultat.sur_taxe ? resultat.sur_taxe : {}}
                        cehr={resultat.contribution_exceptionnelle_hauts_revenus ? resultat.contribution_exceptionnelle_hauts_revenus : {}}
                        ifi={resultat.impact_plafonnement_ifi ? resultat.impact_plafonnement_ifi : {}}
                    />
                </div>
            </DataBlock>
        </div >
    )
}

export default PageHypothesePvImmo;
