import { blackColor } from "../../../all_ppt_render";
import { noteBackgroundFichePVImmo, tableMarginFichePVImmo } from "./tableauRevenuFoncier";
const jaune = 'FFF2CC';
const border = [
    {
        pt: 1,
        color: 'ADB9CA'
    },
    {
        pt: 1,
        color: 'ADB9CA'
    },
    {
        pt: 1,
        color: 'ADB9CA'
    },
    {
        pt: 1,
        color: 'ADB9CA'
    }
]

const titleProps = {
    fill: '8497B0',
    color: 'FFFFFF',
    align: 'center',
    bold: true,
    valign: 'middle',
    border: [...border],
    margin: tableMarginFichePVImmo
}

const valueProps = {
    fontSize: 11,
    fontFace: 'Roboto',
    color: blackColor,
    margin: tableMarginFichePVImmo,
    border: [...border],
}

const firstBorder = [
    {
        pt: 1,
        color: 'FFFFFF'
    },
    {
        pt: 1,
        color: 'ADB9CA'
    },
    {
        pt: 1,
        color: 'FFFFFF'
    },
    {
        pt: 1,
        color: 'FFFFFF'
    }
]

export const synthese1Data = [
    [
        { text: "", options: { border: [...firstBorder] } },
        { text: "REVENUS FONCIERS", options: { fill: jaune, bold: true, rowspan: 2, align: 'center', border: [...border], valign: 'middle', margin: tableMarginFichePVImmo } },
        { text: "LOCATION MEUBLÉE", options: { colspan: 2, bold: true, fill: jaune, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "IMPÔT SUR LES SOCIÉTÉS", options: { bold: true, fill: jaune , align: 'center', rowspan: 2, border: [...border], valign: 'middle', margin: tableMarginFichePVImmo} }
    ],
    [
        { text: "", options: { border: [ ...firstBorder ] } },
        { text: "LMNP", options: { bold: true, fill: noteBackgroundFichePVImmo, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "LMP", options: { bold: true, fill: noteBackgroundFichePVImmo, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
    ],
    [
        { text: "Recettes", options: { ...titleProps } },
        { text: "Encaissées pendant l’année d’imposition", options: { ...valueProps } },
        { text: "Encaissables au titre de l’année d’imposition", options: { ...valueProps, colspan: 3, valign: 'middle' } }
    ],
    [
        {
            text: [
                { text: "Charges", options: { breakLine: true } },
                { text: "déductibles" }
            ],
            options: { ...titleProps }
        },
        {
            text: [
                { text: "Régime micro : 30%.", options: { breakLine: true } },
                { text: "OU", options: { breakLine: true } },
                { text: "Régime réel : charges déductibles restreintes" }
            ],
            options: { ...valueProps, valign: 'middle' }
        },
        {
            text: [
                { text: "Régime micro : 50% (71% pour les meublés de tourisme classés)", options: { breakLine: true } },
                { text: "OU", options: { breakLine: true } },
                { text: "Régime réel : charges déductibles extensives, dont un amortissement qui ne peut cependant pas créer de déficit" }
            ],
            options: { ...valueProps, colspan: 2 }
        },
        { text: "Régime réel : charges déductibles extensives, dont un amortissement déductible sans limite.", options: { ...valueProps, valign: 'middle' } }
    ],
    [
        { text: "Déficit", options: { ...titleProps } },
        {
            text: [
                { text: "Imputé sur le revenu global jusqu’à 10.700 €", options: { breakLine: true } },
                { text: "L’excédent et le déficit lié aux intérêts d’emprunt est reporté sur les revenus fonciers jusqu’à 10 ans" }
            ],
            options: { ...valueProps, valign: 'middle' }
        },
        { text: "Imputé sur les BIC de location meublée jusqu’à 10 ans", options: { ...valueProps, valign: 'middle' } },
        { text: "Imputé sur le revenu global sans limite", options: { ...valueProps, valign: 'middle' } },
        { text: "Imputé sur le bénéfice des années suivantes.", options: { ...valueProps, valign: 'middle' } },
    ],
    [
        { text: "Bénéfice", options: { ...titleProps } },
        {
            text: [
                { text: "IR (jusqu’à 45%)", options: { breakLine: true } },
                { text: "+ PS (17,2%)" }
            ],
            options: { ...valueProps, valign: 'middle' }
        },
        {
            text: [
                { text: "IR (jusqu’à 45%)", options: { breakLine: true } },
                { text: "+ CS si recettes de location saisonnière > 23.000€, sinon PS (17,2%)" }
            ],
            options: { ...valueProps, valign: 'middle' }
        },
        {
            text: [
                { text: "IR (jusqu’à 45%)", options: { breakLine: true } },
                { text: "+ CS" }
            ],
            options: { ...valueProps, valign: 'middle' }
        },
        {
            text: [
                { text: "IS (15% ou 25%)", options: { breakLine: true } },
                { text: "+ CRL (2,5%) sur recettes locatives brutes" }
            ],
            options: { ...valueProps, valign: 'middle' }
        },
    ],
    [
        {
            text: [
                { text: "Distribution", options: { breakLine: true } },
                { text: "du bénéfice" }
            ],
            options: { ...titleProps }
        },
        { text: "Non nécessaire", options: { ...valueProps, colspan: 3, valign: 'middle', align: 'center' } },
        {
            text: [
                { text: "L’associé perçoit les revenus par distribution de dividendes", options: { breakLine: true } },
                { text: "PFU (12,8%) ou IR sur option + PS (17,2%)" }
            ] ,
            options: { ...valueProps }
        }
    ]
]


export const synthese2Data = [
    [
        { text: "", options: { border: [...firstBorder] } },
        { text: "REVENUS FONCIERS", options: { bold: true, rowspan: 2, align: 'center', border: [...border], valign: 'middle', margin: tableMarginFichePVImmo } },
        { text: "LOCATION MEUBLÉE", options: { colspan: 2, bold: true, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "IMPÔT SUR LES SOCIÉTÉS", options: { bold: true, align: 'center', rowspan: 2, border: [...border], valign: 'middle', margin: tableMarginFichePVImmo} }
    ],
    [
        { text: "", options: { border: [...firstBorder] } },
        { text: "LMNP", options: { bold: true, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
        { text: "LMP", options: { bold: true, align: 'center', border: [...border], margin: tableMarginFichePVImmo } },
    ],
    [
        {
            text: [
                { text: "Régime de", options: { breakLine: true } },
                { text: "plus-value" }
            ],
            options: { ...titleProps }
        },
        { text: "Plus-value immobilière des particuliers", options: { ...valueProps, colspan: 2, align: 'center', valign: 'middle', fill: jaune } },
        { text: "Plus-value professionnelle", options: { ...valueProps, colspan: 2, align: 'center', valign: 'middle', fill: jaune } },
    ],
    [
        {
            text: [
                { text: "Calcul de la", options: { breakLine: true } },
                { text: "plus-value" }
            ],
            options: { ...titleProps }
        },
        {
            text: [
                { text: "Plus-value brute = Prix de cession – Prix d’acquisition (majoré des frais d’acquisition et travaux éligibles)", options: { breakLine: true } },
                { text: "", options: { breakLine: true } },
                { text: "La plus-value brute est diminuée d’abattements pour durée de détention." },
            ],
            options: { ...valueProps, valign: 'middle', }
        },
        {
            text: [
                { text: "Plus-value brute = Prix de cession – Prix d’acquisition – Amortissements pratiqués*", options: { breakLine: true } },
                { text: "", options: { breakLine: true } },
                { text: "* Loi de finances 2025", options: { bold: true } },
            ],
            options: { ...valueProps, valign: 'middle', }
        },
        {
            text: [
                { text: "Plus-value de court terme", options: { breakLine: true } },
                { text: "= Prix d’acquisition – VNC", options: { breakLine: true } },
                { text: "", options: { breakLine: true } },
                { text: "Plus-value de long terme", options: { breakLine: true } },
                { text: "= Prix de cession – Prix d’acquisition" },
            ],
            options: { ...valueProps, valign: 'middle' }
        },
        {
            text: [
                { text: "Plus-value de court terme", options: { breakLine: true } },
                { text: "= Prix de cession – VNC" }
            ],
            options: { ...valueProps, valign: 'middle' }
        }
    ],
    [
        {
            text: [
                { text: "Imposition de", options: { breakLine: true } },
                { text: "la plus-value" }
            ],
            options: { ...titleProps }
        },
        {
            text: [
                { text: "La plus-value nette après abattement au titre de l’impôt sur le revenu est soumise à une imposition de 19%.", options: { breakLine: true } },
                { text: "", options: { breakLine: true } },
                { text: "La plus-value nette après abattement au titre des prélèvements sociaux est soumise aux prélèvements sociaux (17,2%)." },
            ],
            options: { ...valueProps, valign: 'middle', colspan: 2 }
        },
        {
            text: [
                { text: "PV de court terme", options: { breakLine: true } },
                { text: "IR (jusqu’à 45%) + CS", options: { breakLine: true } },
                { text: "", options: { breakLine: true } },
                { text: "PV de long terme", options: { breakLine: true } },
                { text: "IR au taux de 12,8% + PS (17,2%)", options: { breakLine: true } },
            ],
            options: { ...valueProps, valign: 'middle' }
        },
        {
            text: [
                { text: "Plus-value de court terme", options: { breakLine: true } },
                { text: "IS (15% ou 25%)" }
            ],
            options: { ...valueProps, valign: 'middle' }
        }
    ],
    [
        {
            text: [
                { text: "Possibilités ", options: { breakLine: true } },
                { text: "d’exonération" }
            ],
            options: { ...titleProps }
        },
        { text: "La plus-value est exonérée d’impôt sur le revenu après un délai de détention de 22 ans, et de prélèvements sociaux après un délai de détention de 30 ans.", options: { ...valueProps, colspan: 2, valign: 'middle' } },
        { text: "Possibilité d’exonération d’IR et PS après 5 années si les recettes sont inférieures à 126.000€, les CS restant dues", options: { ...valueProps, valign: 'middle' } },
        { text: "Aucune exonération possible", options: { ...valueProps, valign: 'middle' } }
    ]
]
